.package {
  .nav-pills {
    border-bottom: 1px solid #d3d3d3;

    .nav-link {
      border-radius: 0;
      color: #676767;
      font-size: 20px;

      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .nav-pills .nav-link.active {
    background-color: transparent;
    border-bottom: 2px solid #0a0a0a;
    color: black;
    font-weight: 590;
  }

  textarea::placeholder {
    font-size: 14px;
  }
}

.Subsciption {
  .Subsciption-title {
    background: linear-gradient(90deg, #121212 50%, #3762ca 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-weight: 590 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ffffff !important;
    background-color: #3772ff !important;
    border-radius: 8px;
    border: none;
    box-shadow: none;

    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .nav-tabs .nav-link {
    box-shadow: none;
    font-weight: 590;
    font-size: 16px;
    line-height: 24px;
    color: #5e5e5e;
    border: none;
    border-radius: 8px;

    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .nav-tabs {
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    // border: none;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }

  .dropdown-toggle::after {
    margin-left: 43px;
    // border: 1px solid;
    box-shadow: 0px 4px 28px 0px #0000001c;
    border: Mixed solid;
    // border-image-source: linear-gradient(to right, #8c8c8c 0%, #3762ca 100%);
  }

  #dropdown-basic {
    padding: 15px 20px;

    @media screen and (max-width: 767px) {
      padding: 10px 13px;
    }
  }
}

.trust {
  .owl-carousel .owl-stage {
    transition-timing-function: linear !important;
  }
}

.goalOriented {
  ul.checklist {
    list-style-type: none;
    padding: 0;
  }

  ul.checklist li {
    position: relative;
    padding-left: 24px;
  }

  ul.checklist li::before {
    content: "✔";
    position: absolute;
    left: 0;
    color: #000000;
    /* You can change the color as needed */
  }

  .set-Grediant {
    position: relative;

    .gridiant-Image {
      position: absolute;
      top: 0;
      right: -90px;
    }
  }

  @media screen and (max-width: 767px) {
    .set-Grediant {
      .gridiant-Image {
        display: none;
      }
    }
  }
}

.Add-Grediant {
  position: relative;

  .gridiant-Image {
    position: absolute;
    top: 0;
    right: 0px;
  }
}

.package-Faqs {
  .accordion {
    display: flex;
    flex-direction: column;
    font-family: "Sora", sans-serif;
  }

  .accordion h1 {
    font-size: 32px;
    text-align: center;
  }

  .accordion-item {
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }

  .accordion-item .accordion-item-title {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    font-weight: 510;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 14px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  .accordion-item .accordion-item-desc {
    display: none;
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: #444;
    border-top: 1px dashed #ddd;
    padding: 10px 20px 20px;
    box-sizing: border-box;
  }

  .accordion-item input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
  }

  .accordion-item input[type="checkbox"]:checked~.accordion-item-desc {
    display: block;
  }

  .accordion-item input[type="checkbox"]:checked~.accordion-item-title .icon:after {
    content: "-";
    font-size: 20px;
  }

  .accordion-item input[type="checkbox"]~.accordion-item-title .icon:after {
    content: "+";
    font-size: 20px;
  }

  .accordion-item:first-child {
    margin-top: 0;
  }

  .accordion-item .icon {
    margin-left: 14px;
  }
}

@media screen and (max-width: 767px) {
  .findNeed-center {
    text-align: center;
  }

  .findNeed h1 {
    text-align: center !important;
  }

  .findNeed span {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .accordion-item .accordion-item-title {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 500;
  }
}

.parent-container {
  display: flex;
  flex-direction: column;
  gap: 100px; /* Instead of margin-top */
}


.back_img {
  background-image: url('../../images/MarketPlace/back.png');
  background-size: cover;
  border-radius: 20px;
  margin-bottom: 50px;
  position: static;
  padding: 50px 20px ;
  // padding-top: 100px;
  @media screen and (max-width:576px){
    padding: 40px 10px;
  
  }
}


.package_slider.owl-theme .owl-nav {
  display: flex;
  justify-content: center;

  @media screen and (min-width:768px) {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.package_slider.owl-carousel .owl-nav button.owl-prev {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  box-shadow: 0 2px 12px #14142b14;

  @media screen and (min-width:768px) {
    position: absolute;
    left: 0;
  }

  &:hover {
    color: #fff !important;
    background-color: #413ada;
  }
}

.package_slider.owl-carousel .owl-nav button.owl-next {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  box-shadow: 0 2px 12px #14142b14;

  @media screen and (min-width:768px) {
    position: absolute;
    right: 0;
  }

  &:hover {
    color: #fff !important;
    background-color: #413ada;
  }
}

.package_slider .item {
  display: flex;
  // justify-content: center;
  align-items: stretch;
}

.package_slider .item>div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}