.marketplace-Categories {
  .accordion-button {
    padding: 10px !important;
    background-color: #ffffff !important;
    border-radius: 0 !important;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .accordion-body {
    background-color: #ffffff !important;
  }

  .Box_shadow {
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.11);
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    box-shadow: none !important;
  }

  .accordion-item:first-of-type>.accordion-header .accordion-button {
    border: 0 !important;
  }

  .form-check-input {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #5e5e5e !important;
  }

  .form-check {
    display: flex !important;
    align-items: center !important;
    gap: 6px;
  }

  .form-check-label {
    margin-top: 4px;
  }

  .accordion-button {
    padding: 0 !important;
  }

  .accordion {
    box-shadow: 0px 4px 28px 0px #0000001c;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
    background: #cdcdcd !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #5e5e5e;
    border-radius: 21px !important;
  }
}

.marketplace-language {
  .accordion-button {
    padding: 10px !important;
    background-color: #ffffff !important;
    border-radius: 0 !important;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .accordion-body {
    background-color: #ffffff !important;
  }

  .Box_shadow {
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.11);
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    box-shadow: none !important;
  }

  .accordion-item:first-of-type>.accordion-header .accordion-button {
    border: 0 !important;
  }

  .form-check-input {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #5e5e5e !important;
  }

  .form-check {
    display: flex !important;
    align-items: center !important;
    gap: 6px;
  }

  .form-check-label {
    margin-top: 4px;
  }

  .accordion-button {
    padding: 0 !important;
  }

  .accordion {
    box-shadow: 0px 4px 28px 0px #0000001c;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
    background: #5e5e5e;
  }

  ::-webkit-scrollbar-thumb {
    background: #cdcdcd !important;
  }
}

.marketplace-region {
  .accordion-button {
    padding: 10px !important;
    background-color: #ffffff !important;
    border-radius: 0 !important;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .accordion-body {
    background-color: #ffffff !important;
  }

  .Box_shadow {
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.11);
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    box-shadow: none !important;
  }

  .accordion-item:first-of-type>.accordion-header .accordion-button {
    border: 0 !important;
  }

  .form-check-input {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #5e5e5e !important;
  }

  .form-check {
    display: flex !important;
    align-items: center !important;
    gap: 6px;
  }

  .form-check-label {
    margin-top: 4px;
  }

  .accordion-button {
    padding: 0 !important;
  }

  .accordion {
    box-shadow: 0px 4px 28px 0px #0000001c;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    width: 6px !important;
    height: 10px !important;
    border-radius: 21px !important;
    background: #5e5e5e;
  }

  ::-webkit-scrollbar-thumb {
    background: #cdcdcd !important;
  }
}

.marketplace-Budget {
  .accordion-button {
    padding: 10px !important;
    background-color: #ffffff !important;
    border-radius: 0 !important;
  }

  .accordion-button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .accordion-body {
    background-color: #ffffff !important;
  }

  .Box_shadow {
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.11);
  }

  .accordion-button:not(.collapsed) {
    color: #000 !important;
    box-shadow: none !important;
  }

  .accordion-item:first-of-type>.accordion-header .accordion-button {
    border: 0 !important;
  }

  .accordion-button {
    padding: 0 !important;
  }

  .accordion {
    box-shadow: 0px 4px 28px 0px #0000001c;
  }

  #first,
  #second,
  #third,
  #fourth {
    width: 24px !important;
    height: 24px !important;
  }

  .form-check {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .form-check-input {
    border: 1px solid #5e5e5e;
  }

  .form-check-label {
    margin-top: 4px;
  }
}

.search-box {
  width: 350px;
  position: relative;
  display: flex;
  bottom: 0;
 
  left: 0;
  right: 0;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.search-input {
  width: 100%;
  font-weight: 510;
  font-size: 16px;
  padding: 15px 13px;
  background-color: #ffffff;
  color: #000;
  border-radius: 8px;
  border: none;
  transition: all 0.4s;
  // border: 1px solid #afafaf;

  ::placeholder {
    font: 510;
    font-size: 16px;
    line-height: 19.2px;
    color: #a5a5a5;
  }
}

.search-input:focus {
  outline: none;
  box-shadow: 0 1px 12px #b8c6db;
  -moz-box-shadow: 0 1px 12px #b8c6db;
  -webkit-box-shadow: 0 1px 12px #b8c6db;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-left: -45px;
  border: none;
  color: #6c6c6c;
  transition: all 0.4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline: none;
  color: black;
}

.filter-btn {
  .firstFilter {
    padding: 20px !important;
    border: 1px solid #2AABEE;
    height: 48px !important;
    background-color: transparent !important;
    gap: 8px;
    display: flex;
    gap: 16px;
    // margin-right: 12px;
    align-items: center;
  }
}

.sliders {
  .owl-theme .owl-nav.disabled+.owl-dots {
    position: absolute !important;
    top: -12px !important;
    right: 0px !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    margin: 6px !important;
  }

  background-color: #ffffff;
  box-shadow: 0px 4px 27px 0px #0000000a;
  border: 0.5px solid #dbdbdb;
  border-radius: 12px;
  padding: 12px 17px;
}

.roadmap {
  background: linear-gradient(90deg, #d6e2ff -19.01%, #3762ca 100%);
  padding: 16px 20px;
  border-radius: 12px;
}

.market_slide {
  .owl-dots .owl-dot.active span {
    background-color: #000 !important;
    transform: scale(1.1);
  }
}

.include {
  // background: linear-gradient(91.14deg, #151617 1.22%, #6361c4 101.35%);
  background: linear-gradient(91.14deg, #240270 1.22%, #641EFD 101.35%);
  border: 0.5px solid #e7e7e7;
  border-radius: 12px;
  color: #fff;
}
.include_skeleton {
  // background: linear-gradient(91.14deg, #151617 1.22%, #6361c4 101.35%);
  background: linear-gradient(91.14deg, white 1.22%, #f2f1f4 101.35%);
  border: 0.5px solid #e7e7e7;
  border-radius: 12px;
  color: #fff;
}

.suggest_btn {
  border-radius: 8px;
  // background-image: linear-gradient(90deg, #d6e2ff 0%, #3762ca 100%);
  background-image: linear-gradient(90deg, #FFF1D9 0%, #FBD482 80%);
  padding: 1px;
  width: max-content;
  overflow: hidden;

  button {
    background-color: #fff;
    border-radius: 8px;
  }
}

.price_btn {
  &::after {
    display: none;
  }
}

.package_scroll {
padding-bottom: 10px;
  &::-webkit-scrollbar {
    height: 5px;
    border-radius: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

.blue-gradient {
  border: 2px solid;
  border-image-source: linear-gradient(180deg, #2AABEE 0%, #229ED9 10000%);
  border-image-slice: 1;
}

.border-rights {
  border-right: 0 !important;
}

.buttons {
  .form-check-input:checked {
    background-color: #000 !important;
  }
}

.active-borders {
  &::after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 43px 43px 0 0;
  }
}