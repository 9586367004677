@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('../public/assets/fonts/SFProDisplay-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
    url('../public/assets/fonts/SFProDisplay-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Light.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('../public/assets/fonts/SFProDisplay-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Black.eot');
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
    url('../public/assets/fonts/SFProDisplay-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Black.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Black.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('../public/assets/fonts/SFProDisplay-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Semibold.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/assets/fonts/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('../public/assets/fonts/SFProDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../public/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('../public/assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'SF Pro Display' !important;
  background-color: #f9f9f9 !important;
}

.loader {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #514b82;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes crackerExplosion {
  0% {
    transform: translateY(0) scale(0.5);
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) scale(1.2) rotate(180deg);
    opacity: 0.9;
  }
  60% {
    transform: translateY(-50px) scale(1.5) rotate(360deg);
    opacity: 0.6;
  }
  100% {
    transform: translateY(-100px) scale(2) rotate(720deg);
    opacity: 0;
  }
}

.cracker {
  animation: crackerExplosion 2s ease-out forwards;
}

.cracker:nth-child(odd) {
  animation-duration: 1.8s; /* Slightly faster animation for odd elements */
}

.cracker:nth-child(even) {
  animation-duration: 2.2s; /* Slightly slower animation for even elements */
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

@keyframes borderRun {
  0% {
    border-image-source: linear-gradient(90deg, transparent, #121212);
    border-image-slice: 1;
  }
  25% {
    border-image-source: linear-gradient(180deg, transparent, #121212);
  }
  50% {
    border-image-source: linear-gradient(270deg, transparent, #121212);
  }
  75% {
    border-image-source: linear-gradient(360deg, transparent, #121212);
  }
  100% {
    border-image-source: linear-gradient(90deg, transparent, #121212);
  }
}

@layer utilities {
  .animate-borderRun {
    animation: borderRun 4s linear infinite;
    border-width: 2px;
    border-style: solid;
    border-image-slice: 1;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
