.gradient-text {
  background: linear-gradient(90deg, #d6e2ff 0%, #3762ca 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.dashboard {
  ul li label i:first-child {
    color: rgb(119, 119, 119);
  }

  /* Start Radio inputs */
  input[name="menu"],
  input[name="dropdowns"] {
    display: none;
  }

  input:not(checked) ~ label {
    color: rgb(60, 33, 33);
  }

  /* End Radio inputs */

  /* Start Checkbox inputs */
  input[type="checkbox"]:checked ~ ul {
    display: block;
  }

  #uparrow,
  #downarrow {
    float: right;
  }

  input[name="dropdowns"]:checked ~ label > #downarrow {
    display: none;
  }

  input[name="dropdowns"]:checked ~ label > #uparrow {
    display: inline-block;
  }

  input[name="dropdowns"]:not(checked) ~ label > #uparrow {
    display: none;
  }

  /* End Checkbox inputs */

  /* Start Menu */

  ul li label {
    display: inline-block;
  }

  ul li label:hover {
    cursor: pointer;
  }

  .main {
    list-style-type: none;
    padding-left: 0;
  }

  .drop {
    display: none;
  }

  .drop li {
    display: block;
    font-size: 14px;
    padding-left: 20px;
  }

  .drop li label {
    width: 105px;
  }

  .metric-card {
    max-width: 250px;
    width: auto;
  }

  .marketplace-card {
    max-width: 350px;
    width: auto;
  }
}

.metric-info {
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #eff4ff;
  border-radius: 2px;
  padding: 2px 4px;
}

.example {
  ::-webkit-scrollbar {
    width: 0;
  }
  .owl-theme .owl-dots .owl-dot.active span {
    background-color: #000;
    transform: scale(1.2);
  }
}
