.Add-Offering {
  .form-group {
    display: block;
    margin-bottom: 15px;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #7d7d7d;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label::before {
    background-color: #20b038;
  }

  .form-group input:checked + label:after {
    content: "";
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.AddOffering_details {
  #input-file-upload {
    display: none;
  }

  #input-file-upload-logo {
    display: none;
  }

  .image_upload {
    border: 1px solid #dadada;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .image_upload_AddOffreing {
    border: 3px dashed #1e77cc;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .upload-button {
    border: 1px solid #7093ff;
    padding: 10px 30px;
    border-radius: 4px;
  }
}

.Outline:focus {
  outline: none;
}

.Outline::placeholder {
  color: #474747;
}

.accordion-button {
  padding: 10px !important;
  background-color: #dbdbdb !important;
  border-left: 2px solid #000000 !important;
  border-radius: 0 !important;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-body {
  background-color: #f9f9f9 !important;
}

.Box_shadow {
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.11);
}

.accordion-button:not(.collapsed) {
  color: #3c3c3c !important;
}
