.identification_details {
  #input-file-upload {
    display: none;
  }

  .image_upload {
    border: 3px dashed #1e77cc;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .upload-button {
    border: 1px solid #7093ff;
    padding: 10px 30px;
    border-radius: 4px;
  }
}

.border-gray-500 {
  border-color: #7d7d7d;
}

.border-green-500 {
  border-color: #20b038;
}

.bg-green-500 {
  background-color: #20b038;
}

.check_btn {
  @media screen and (max-width: 767px) {
    margin: auto;
  }
}
