.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input::placeholder {
  opacity: 0.5;
  /* 80% opacity */
}

textarea::placeholder {
  opacity: 0.5;
  /* 80% opacity */
}

.exampleX {
  overflow-x: auto;
  /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.exampleX::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.exampleX {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Apply consistent styling to all <select> elements */
select {
  width: 100%;
  padding: 13px 10px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  background-color: white;
  color: #474747;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  appearance: none;
  /* Remove native styling */
  -webkit-appearance: none;
  /* Safari-specific */
  -moz-appearance: none;
  /* Firefox-specific */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23474747'%3E%3Cpath fill-rule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
}



/* Focus state for better accessibility */
select:focus {
  outline: none;
}

/* Disabled state styling */
select:disabled {
  background-color: #f5f5f5;
  color: #a1a1a1;
  cursor: not-allowed;
}

/* Placeholder-like styling for the default option */
select option[disabled] {
  color: #a1a1a1;
}

/* Optional: Styling for individual <option> elements */
select option {
  font-size: 14px;
  font-weight: 400;
  color: #474747;
}

.contentWrapper ul {
  padding-left: 13.5rem; 
  list-style-position: outside; 
}

.contentWrapper li {
  padding-left: 13.5rem; 
  text-indent: -0.5rem; 
  margin-left: 0.5rem;
}


.btn_hover:hover{
  background-color: #413ada !important;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.animate-lds-ellipsis1 {
  animation: lds-ellipsis1 0.6s infinite cubic-bezier(0, 1, 1, 0);
}

.animate-lds-ellipsis2 {
  animation: lds-ellipsis2 0.6s infinite cubic-bezier(0, 1, 1, 0);
}

.animate-lds-ellipsis3 {
  animation: lds-ellipsis3 0.6s infinite cubic-bezier(0, 1, 1, 0);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.firstFilter {
  border: 2px solid black !important;
  box-shadow: none !important; 
}

.firstFilter:focus,
.firstFilter:active,
.firstFilter:focus-visible {
  border: 2px solid black !important;
  box-shadow: none !important;
  outline: none !important; 
  
}

.dropdown-toggle::after {
  display: none !important;  
  content: "" !important;
}
.firstFilter,
.dropdown-menu {
  background-color: #f5f5f5!important; 
  border: 1px solid black !important;
}

.dropdown-menu .dropdown-item {
  background-color: #f5f5f5 !important;
  color: black !important;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #e0e0e0 !important; 
}


@keyframes shine {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.animate-shine {
  background-size: 200% auto;
  animation: shine 2.5s linear infinite;
}


/* Remove blue background from dropdown toggle when opened/closed */
.btn:focus,
.btn:active,
.show > .btn {
    background-color: #f3f4f6 !important; /* gray-100 */
    color: black !important;
    box-shadow: none !important;
    outline: none !important;
}

/* Remove blue focus ring */
.dropdown-toggle:focus,
.dropdown-toggle:active {
    background-color: #f3f4f6 !important;
    color: black !important;
    box-shadow: none !important;
    outline: none !important;
}



.grid-view {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  transition: all 0.3s ease-out;
}

.list-view {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.offering-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid-item {
  transition: all 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}


.slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  font-family: "sf Pro Display";
}

.slider-track {
  display: flex;
  gap: 10px; /* Adjust spacing */
  animation: scroll 30s linear infinite;
  width: calc(285px * 15 * 2); /* Ensure it loops seamlessly */
}

.slide {
  flex-shrink: 0;
  width: 285px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-285px * 15));
  }
}
