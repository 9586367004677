@import './sign-up/signup.scss';
@import './account-detail/account.scss';
@import './package/package.scss';
@import './dashboard/dashboard.scss';
@import './add-offer/addoffer.scss';
@import './Marketplace/marketplace.scss';

ul {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.blue-color {
    color: #3772FF;
}

.shadows {
    box-shadow: 0px 0px 5px 0px #00000012;
}

.bg-black-color {
    background-color: #0A0A0A;
}

input {
    &:focus {
        box-shadow: none !important;
    }
}

.borders {
    border: 0.89px solid #DBDBDB;
}

.drafts {
    .accordian-button {
        box-shadow: none !important;
    }
}

.text-gradient {
    background: linear-gradient(90deg, #558BF5 10%, #000000 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-color {
    background: linear-gradient(90deg, #240270 0%, #641EFD 100%);
}

.bg-gradient-color1 {
    background: linear-gradient(180deg, #240270 0%, #641EFD 100%);
}